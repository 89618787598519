<template>
    <div class="px-4 font-inter flex flex-col justify-center h- login">
        <div>
            <div class="text-center pb-4">
                <img class="mx-auto" width="80" src="http://192.168.1.4/storage/app/public/business/2024-01-18-65a9885147d16.png" alt="" srcset="">
                <h1 class="text-3xl font-bold font-quicksand">Área de login</h1>
                <p class="text-d5 text-sm text-gray-500">Acesse o sistema para registrar pedidos</p> 
            </div>
            <form @submit.prevent="" action="" class="flex flex-col gap-4 my-auto">
                <div class="flex flex-col gap-2">
                    <label class="text-md font-medium" for="email">Email <span class="text-red-500">*</span></label>
                    <input type="text" class="p-4 py-3 border w-full rounded-xl input-primary text-sm md:text-md" placeholder="ex: seuemail@gmail.com" v-model="loginForm.email" id="email">
                </div>
                <div class="flex flex-col gap-2">
                    <label class="text-md font-medium" for="password">Senha <span class="text-red-500">*</span></label>
                    <div class="relative">
                        <input :type="showPassword ? 'text':'password'" class="p-4 border py-3 w-full rounded-xl input-primary text-sm md:text-md" placeholder="ex: 878273Fy" v-model="loginForm.password" id="password">
                        <button @click="showPassword = !showPassword" class="absolute right-[5%] top-1/2 -translate-y-1/2">
                            <svg v-if="showPassword" viewBox="0 0 24 24" role="presentation" style="width: 1.8rem; height: 1.8rem;"><path d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z" style="fill: gray;"></path></svg>
                            <svg v-else viewBox="0 0 24 24" role="presentation" style="width: 1.8rem; height: 1.8rem;"><path d="M2,5.27L3.28,4L20,20.72L18.73,22L15.65,18.92C14.5,19.3 13.28,19.5 12,19.5C7,19.5 2.73,16.39 1,12C1.69,10.24 2.79,8.69 4.19,7.46L2,5.27M12,9A3,3 0 0,1 15,12C15,12.35 14.94,12.69 14.83,13L11,9.17C11.31,9.06 11.65,9 12,9M12,4.5C17,4.5 21.27,7.61 23,12C22.18,14.08 20.79,15.88 19,17.19L17.58,15.76C18.94,14.82 20.06,13.54 20.82,12C19.17,8.64 15.76,6.5 12,6.5C10.91,6.5 9.84,6.68 8.84,7L7.3,5.47C8.74,4.85 10.33,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C12.69,17.5 13.37,17.43 14,17.29L11.72,15C10.29,14.85 9.15,13.71 9,12.28L5.6,8.87C4.61,9.72 3.78,10.78 3.18,12Z" style="fill: gray;"></path></svg>
                        </button>
                    </div>
                </div>
                <!-- <div class="flex items-center gap-4 py-4">
                    <input type="checkbox">
                    <label class="text-sm text-gray-500">Lembre de mim</label>
                </div> -->
                <div>
                    <button @click="login" class="w-full p-4 py-3 text-white rounded-xl font-medium sm:mt-14 transition duration-200"
                    :style="{background:  loginForm.email && loginForm.password ? '#323232' : '#f5f5f5', color: loginForm.email && loginForm.password ? '#fff' : '#949494'}">Entrar</button>
                </div>
            </form>
        </div>
        <CentralSpinner v-if="isLoading" :zIndex="10"/>
    </div>
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import CentralSpinner from '../../components/loaders/CentralSpinner.vue'
import axiosClient from '../../api/axios'
import { isLoading } from '@/global/state/GlobalState';
import {useToast} from "vue-toastification";
import router from '@/router';

const toast = useToast()

const loginForm = ref<any>({
    email:'',
    password:'',
    role: 'vendor_employee'
})

const showPassword = ref<boolean>(false)

onMounted(() => {
    const link = document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = '/favicon.png';
    document.head.append(link)
})

function login(){
    if(loginForm.value.email && loginForm.value.password){
        axiosClient.post('/v1/auth', loginForm.value)
        .then((res: any) => {
            localStorage.setItem('token', res.data.token)
            toast.success('Logado com sucesso')
            router.push({name: 'sell'})
        })
        .catch((error: any) => {
            toast.error(error?.response?.data.message ?? 'Credenciais inválidas')
        })
    }
}
</script>

<style lang="scss">
$primary-color:#c46c2c;

.login{
    min-height:85vh;

    .input-primary:focus{
        border-color:$primary-color;
        outline:1px solid $primary-color !important;
    
        &::placeholder{
            color: $primary-color
        }
    
        & + svg path{
            fill: $primary-color !important
        }
    }
}
</style>