import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getMessaging, onMessage, getToken } from "firebase/messaging";
// import { cmFirebaseToken } from '@/global/state/GlobalState';
import { appearNotification, notifications } from '@/global/state/NotificationState';
import { commands, restaurant } from '@/global/state/GlobalState';
import axiosClient from '@/api/axios';

const firebaseConfig = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_APP_ID,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp)

const dbName = 'notificationsDB';
const dbVersion = 1;
const db: any = await new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, dbVersion);

    request.onupgradeneeded = (event: any) => {
        const db = event.target.result;

        // Verifique a versão e crie ou atualize a estrutura do banco de dados
        if (event.oldVersion < 1) {
        // Crie a object store 'notifications' durante o evento 'upgradeneeded'
        if (!db.objectStoreNames.contains('notifications')) {
            db.createObjectStore('notifications', { keyPath: 'id', autoIncrement: true });
        }
        }
    };

    request.onsuccess = (event: any) => resolve(event.target.result);
    request.onerror = (event: any) => reject(event.target.error);
});

export async function addNotifications(notificationData: any){
    const notificationsStore = db.transaction(['notifications'], 'readwrite').objectStore('notifications');

    for(let notification of notificationData){
        const requestAdd = notificationsStore.add(notification);

        requestAdd.onsuccess = function(event: any) {
            console.log('Notification added to IndexedDB');
        };
    
        requestAdd.onerror = function(event: any) {
            console.error('Error adding notification to IndexedDB:', event.target.error);
        };
    }
}

export async function updateRecord(recordId: any, updatedData: any) {
    try {
      const transaction = db.transaction(['notifications'], 'readwrite');
      const objectStore = transaction.objectStore('notifications');
  
      // Obtenha o registro pelo ID
      const getRequest = objectStore.get(recordId);
  
      getRequest.onsuccess = function (event: any) {
        const existingRecord = getRequest.result;
  
        // Atualize os dados
        if (existingRecord) {
          // Atualize os campos necessários
          Object.assign(existingRecord, updatedData);
  
          // Use put() para atualizar o registro
          /*const putRequest = */objectStore.put(existingRecord);
  
          // putRequest.onsuccess = function (event: any) {
          //   console.log('Registro atualizado com sucesso!');
          // };
  
          // putRequest.onerror = function (event: any) {
          //   console.error('Erro ao atualizar o registro:', event.target.error);
          // };
        } else {
          console.error('Registro não encontrado');
        }
      };
  
      transaction.oncomplete = function (event: any) {
        console.log('Transação concluída');
      };
  
      transaction.onerror = function (event: any) {
        console.error('Erro na transação:', event.target.error);
      };
    } catch (error) {
      console.error('Erro ao abrir o IndexedDB:', error);
    }
}

export async function deleteNotification(notificationId: number){
  const objectStore = db.transaction(['notifications'], 'readwrite').objectStore('notifications')
  objectStore.delete(notificationId)
}

export function clearNotifications(){
    const objectStore = db.transaction(['notifications'], 'readwrite').objectStore('notifications')
    objectStore.clear()
}

onMessage(messaging, async (payload: any) => {
    if(payload.data.type == 'call_waiter'){
        const notificationData = {
            order_id: payload.data.order_id,
            title: `Nova solicitação`,
            message: payload.data.body,
            customerCpf: payload.data.customer_cpf,
            date: new Date(),
            restaurant_id: restaurant.value.id,
            type:payload.data.type,
            is_read:false
        }

        notifications.value.push(notificationData)
        addNotifications([notificationData])
        appearNotification(payload.data.title, payload.data.body)
    } else if(payload.data.type == 'order_sauce'){
        const notificationData = {
            order_id: payload.data.order_id,
            title: `Nova solicitação`,
            message: payload.data.title,
            body: payload.data.body.split(','),
            customerCpf: payload.data.customer_cpf,
            date: new Date(),
            restaurant_id: restaurant.value.id,
            type:payload.data.type,
            is_read:false
        }
        
        notifications.value.push(notificationData)
        addNotifications([notificationData])
        appearNotification(payload.data.title, payload.data.body)
    } else if(payload.data.type == 'order_utensils'){
        const notificationData = {
          order_id: payload.data.order_id,
          title: 'Nova solicitação de utensilho(s)',
          message: payload.data.title,
          body: payload.data.body.split(','),
          customerCpf: payload.data.customer_cpf,
          date: new Date(),
          type:payload.data.type,
          is_read:false
        }
    
        notifications.value.push(notificationData)
        addNotifications([notificationData])
        appearNotification(payload.data.title, payload.data.body)
      } else if(payload.data.type == 'order_bill'){
        const notificationData = {
          order_id: payload.data.order_id,
          title: payload.data.body,
          message: payload.data.title,
          payment_method: payload.data.payment_method,
          customerCpf: payload.data.customer_cpf,
          date: new Date(),
          type:payload.data.type,
          is_read:false,
        }
    
        notifications.value.push(notificationData)
        addNotifications([notificationData])
        appearNotification(payload.data.title, payload.data.body)
    } else if(payload.data.type == 'order_status' || payload.data.type == 'kitchen_status' || payload.data.type == 'counter_status'){
        const statusTranslations: any = {
            'pending': {label: 'pendente', color:'#918373'},
            'preparing': {label: 'preparando', color:'#FF9B3D'},
            'prepared': {label: 'preparado', color:'#59A6F8'},
            'delivered': {label: 'entregue', color: '#00c9a7'},
            'cancelled': {label: 'cancelado', color:'#F83535'},
            'finished': {label:'finalizado'}
        }
        const statusType = payload.data.type == 'order_status' ? '' : (payload.data.type == 'kitchen_status' ? ' de cozinha' : ' de balcão')
        const notificationData = {
            order_id: payload.data.order_id,
            title: 'Seu pedido foi atualizado',
            message: `O status${statusType} do seu pedido mudou de ${statusTranslations[payload.data.old_status].label} para ${statusTranslations[payload.data.new_status].label}`,
            date: new Date(),
            restaurant_id: restaurant.value.id,
            type:payload.data.type,
            new_status: payload.data.new_status,
            is_read:false,
        }

        notifications.value.push(notificationData)
        addNotifications([notificationData])

        appearNotification('Seu pedido foi atualizado',`O status do seu pedido mudou de ${statusTranslations[payload.data.old_status].label} para ${statusTranslations[payload.data.new_status].label}`)
    } else if(payload.data.type == 'update_commands_view'){
        console.log(payload.data.type, 'update_commands_view')
        axiosClient.get('/v1/vendor/pos/commands?status=pending')
        .then((res: any) => {
            let _commands: any = {}
            res.data.commands.forEach((command: any) => {
                _commands[command.command_number] = [...(_commands[command.command_number] ?? []), command]
            })
            commands.value = _commands
        })
    }
});  

getToken(messaging, { vapidKey: 'BMO5D3aM00o8csdPvxIFkMLZa6ad0aOswN3cl-uQVl-NbgZB8dqsWWeKSkGbN4hA9N-NgsrcD6yS1CMNPhjp04g' }).then((currentToken: any) => {
    if (currentToken) {
        if(currentToken != localStorage.getItem('cmFirebaseToken')){
            console.log('atualizar cm-token')
            axiosClient.post('/v1/vendor/create-fcm-token', {
                cm_firebase_token: currentToken,
            }).then((res: any) => {
                console.log('cm firebase token updated succesfully')
                localStorage.setItem('cmFirebaseToken', currentToken)
                // cmFirebaseToken.value = currentToken
            })
        }

    } else {
        // Show permission request UI
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                console.log('Permissão para notificações concedida.');
            } else {
                console.log('Permissão para notificações negada.');
            }
        });

        console.log('No registration token available. Request permission to generate one.');
        // ...
    }
}).catch((err:any) => {
    console.log('An error occurred while retrieving token. ', err);
    // ...
});

export default messaging