<template>
  <router-view/>
</template>

<style lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;
@import './global/scss/app.scss';

*{
  box-sizing:border-box
}
</style>
