<template>
    <div class="select font-inter relative">
        <div id="category-select">
            <!-- <label for="options-view-button">Categoria</label> -->
            <input type="checkbox" :class="`options-view-button-${name}`" id="options-view-button">

            <div id="select-button" class="rounded-xl">
                <div style="color:#575757" id="selected-value" v-html="currentValue?.name ? currentValue.name : placeholder"></div>

                <div id="chevrons">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="8" viewBox="0 0 15 8" stroke="none" class="rotate90deg"><path d="M7.5,105.14a1.085,1.085,0,0,1-.743-.288L.308,98.818a.939.939,0,0,1,0-1.39,1.1,1.1,0,0,1,1.486,0l5.707,5.34,5.707-5.339a1.1,1.1,0,0,1,1.485,0,.938.938,0,0,1,0,1.39l-6.45,6.035A1.085,1.085,0,0,1,7.5,105.14Z" transform="translate(-0.001 -97.14)"></path></svg>
                </div>
            </div>
        </div>

        <div id="option" class="rounded-xl absolute w-full">
            <ul class="overflow-hidden">
                <li v-for="(option, index) in options" :key="index" :class="index == options.length - 1 ? (options.length == 1 ? 'rounded-xl':'rounded-bl-xl rounded-br-xl') : (index == 0 ? 'rounded-tl-xl rounded-tr-xl':'')" class="option">
                    <input type="radio" :name="name" @input="onSelectOption(option)" :value="option.value" data-label="padaria" :checked="option.checked ? true:false">
                    <span class="w-full" v-html="option.name"></span>
                    <svg viewBox="0 0 24 24" role="presentation" style="width: 1.8rem; height: 1.8rem;"><path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" style="fill: currentcolor;"></path></svg>
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { restaurant } from '../../global/state/GlobalState'
const props = defineProps<{placeholder:string,currentValue: any, setCurrentValue:Function, options: any[], name: string}>()

const closeSelect = () => (document.querySelector(`.options-view-button-${props.name}`) as HTMLElement).click()
function onSelectOption(option: any){
    props.setCurrentValue(option)
    closeSelect();
}

</script>

<style scoped lang="scss">
    $primary-color: v-bind('restaurant.restaurant_design_custom?.primary_color');
    $secondary-color: v-bind('restaurant.restaurant_design_custom?.secondary_color');

    #category-select{
        label{
            font-size:0.75rem;
            letter-spacing: 0.0225rem;
        }
    }

    #select-button{
        margin-top:.5rem;
        display: flex;
        padding:.5rem;
        align-items: center;
        justify-content: space-between;
        border:1px solid #D5D5D5;
        background-color: transparent ;
    }

    #options-view-button:focus + #select-button,
    #options-view-button:checked + #select-button{
        outline:1px solid $primary-color;
        border:1px solid $primary-color;
    }

    #category-select:has(#options-view-button:checked) label,
    #options-view-button:checked + #select-button #chevrons svg{
        transform:rotate(180deg)
    }

    #category-select{
        position:relative
    }

    #options-view-button{
        all:unset;
        position:absolute;
        inset:0;
        cursor:pointer;
        z-index:3;
    }

    #option{
        margin-top: .25rem;
        background:#fff;
    }

    .option{
        display: flex;
        align-items: center;
        gap:.5rem;
        padding:.5rem;
        width:100%;
        position:relative;

        svg{
            margin-left:auto;
            display: none;
        }

        li{
            span{
                color:#575757;
                font-weight:500
            }
        }
    }
    .option:has(input:checked), .option:hover{
        background-color: #dcefea
    }
    .option:has(input:focus){
    }
    .options:has(input:checked) svg{
        display:block
    }

    .option input[type="radio"]{
        all:unset;
        position:absolute;
        inset:0;
        cursor:pointer;
    }

    #option{
        display:grid;
        grid-template-rows: 0fr;
    }

    #category-select:has(#options-view-button:checked) + #option{
        grid-template-rows: 1fr;
        border:1px solid $primary-color;
        outline:1px solid $primary-color;
        transition:.1s grid-template-rows;

        ul{
            max-height:21.1rem;
            overflow: auto;
        }
    }
</style>
