import {ref} from 'vue'

export const showNotification = ref<boolean>(false)
export const notifications = ref<any>([])
export const notification = ref<{title: string, message: string}>({
    title:'',
    message:''
})

const dbName = 'notificationsDB';
const dbVersion = 1;

const openDB = async () => {
  return new Promise<IDBDatabase>((resolve, reject) => {
    const request = indexedDB.open(dbName, dbVersion);

    request.onupgradeneeded = (event: any) => {
      const db = event.target.result;

      // Verifique a versão e crie ou atualize a estrutura do banco de dados
      if (event.oldVersion < 1) {
        // Crie a object store 'notifications' durante o evento 'upgradeneeded'
        if (!db.objectStoreNames.contains('notifications')) {
          db.createObjectStore('notifications', { keyPath: 'id', autoIncrement: true });
        }
      }
    };

    request.onsuccess = (event: any) => {
      const db = event.target.result;
      resolve(db);
    };

    request.onerror = (event: any) => {
      reject(event.target.error);
    };
  });
};

const fetchNotifications = async () => {
  try {
      const db: any = await openDB();
      
      const transaction = db.transaction(['notifications'], 'readonly');
      const notificationsStore = transaction.objectStore('notifications');
      const requestGetAll = notificationsStore.getAll();

      requestGetAll.onsuccess = (event: any) => {
          notifications.value = event.target.result;
      };

      requestGetAll.onerror = (event: any) => {
      console.error('Error getting notifications from IndexedDB:', event.target.error);
      };
  } catch (error) {
      console.error('Error opening IndexedDB:', error);
  }
};
  
// Chame a função de recuperação quando o módulo for carregado
fetchNotifications();

export function appearNotification(title: string, message: string){
    const audio = document.getElementById('notificationAudio') as HTMLAudioElement
    
    const playPromise = audio.play();

    if (playPromise !== undefined) {
        playPromise.then(_ => {
            // Autoplay started successfully
        }).catch(error => {
            // Autoplay was prevented, handle the error or provide alternative user interaction
            console.error('Autoplay was prevented:', error);
        });
    }

    showNotification.value = true
    notification.value = {
        title: title,
        message: message
    }
    setTimeout(() => {
        showNotification.value = false
    }, 3000)
}

document.addEventListener('visibilitychange', function() {
  if (document.visibilityState === 'visible') {
    fetchNotifications();
  } 
});