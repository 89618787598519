import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import LoginView from '../views/auth/LoginView.vue'
import SellView from '../views/sell/SellView.vue'
import TablesView from '../views/table/TablesView.vue'
import MainLayout from '../layouts/MainLayout.vue'
import PosView from '../views/pos/PosView.vue'
import OrdersView from '../views/order/OrdersView.vue'

function authMiddleware(to: any, from: any, next: Function) {
  if (localStorage.getItem('token')) {
    next(); 
  } else {
    next('/login');
  }
}

function guestMiddleware(to: any, from: any, next: Function) {
  if (localStorage.getItem('token')) {
    next('/'); 
  } else { 
    next();
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }, 
  {
    path:'/login',
    name:'login',
    component: LoginView,
    beforeEnter: guestMiddleware,
  },
  {
    path:'/',
    component:MainLayout,
    beforeEnter: authMiddleware,
    children: [
      // {
      //   path: '/',
      //   name: 'home',
      //   component: HomeView
      // },
      {
        path: '/',
        name:'sell',
        component: SellView
      },
      {
        path: '/tables',
        name:'tables',
        component: TablesView
      },
      {
        path: '/pos/:command_id?',
        name:'pos',
        props: true,
        component: PosView
      },
      { path: '/orders/:id?', name: 'orders', component: OrdersView, props: true},

    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
