import { ref } from 'vue'

export const apiBaseUrl = process.env.VUE_APP_API_BASE_URL
export const isLoading = ref<boolean>(false)
export const business = ref<any>()
export const restaurant = ref<any>({})
export const addOns = ref<any>({})
export const cart = ref<any>(JSON.parse(localStorage.getItem('cart') as string) ? JSON.parse(localStorage.getItem('cart') as string) : [])
export const commands = ref<any>([])
export const selectedCommand = ref<any>({})
export const categories = ref<any | null>(null)
export const selectedProduct = ref<any>({})
export const me = ref<any>({})
export const orders = ref<any[]>([])